// Modal Search (component searchModal.ejs)
.search-modal {
  padding: 60px 30px;
  padding-left: 30px !important;
  @media #{$md-up}{
    padding: 80px 40px;
    padding-left: 40px !important;
  }

  // Settings Modal Style
  .modal-dialog {
    max-width: 800px;
  }
  .modal-content {
    outline: 0;
    padding: 0;
    border: none;
    background: transparent;
  }
  .modal-body {
    padding: 0;
  }
  .modal-backdrop.show {
    opacity: 1;
  }
  .modal-backdrop.fade {
    opacity: 0;
  }
  .btn-close {
    background-image: url(/public/img/icons/svg/close-cross.svg);
    width: 16px;
    height: 16px;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    background-size: 20px;
    background-repeat: no-repeat;
    outline: none;
    box-shadow: none;
    opacity: 1;
  }

  // Settings Container Result Style
  .container-fluid {
    max-width: 1440px;
    padding: 0;
    padding-top: 40px;
    .searchResult-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2%;
      @media #{$md-up}{
        justify-content: start;
        gap: 3%;
      }
      @media #{$lg-up}{
        gap: 2.5%;
        justify-content: start;
      }
      @media #{$xl-up}{
        gap: 1%;
        justify-content: start;
      }
      .searchResult-col {
        width: 100%;
        padding-bottom: 20px;
        @media #{$sm-up}{
          width:48%;
        }
        @media #{$md-up}{
          width:31%;
        }
        @media #{$lg-up}{
          width:23%;
        }
        @media #{$xl-up}{
          width: 19%;
        }
      }
    }
    .trendingResult-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2%;
      @media #{$md-up}{
        justify-content: start;
        gap: 3%;
      }
      @media #{$lg-up}{
        gap: 1%;
        justify-content: space-between;
      }
      .trendingResult-col {
        width: 100%;
        display: flex;
        padding-bottom: 20px;
        @media #{$sm-up}{
          display: block;
          width:48%;
        }
        @media #{$md-up}{
          width:31%;
        }
        @media #{$lg-up}{
          width:23%;
        }
        @media #{$xl-up}{
          width: 19%;
        }
        &-image {
          width:45%;
          @media #{$sm-up} {
            width: 100%
          }
        }
        &-desc {
          width:55%;
          padding: 1rem;
          @media #{$sm-up} {
            width: 100%;
            padding: 0;
          }
        }
      }
    }
  }
  // Search Content Text Result
  .heading_section-title {
    font-family: $font-family-base;
    font-weight: 400 ;
    color:$viridian;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.07rem;
    text-align: center;
    line-height: 1rem;
    margin-bottom: 2rem;
  }
  .title-search-result {
    text-transform: uppercase;
    font-family: $font-family-title !important;
    font-size: 0.9rem;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 5px;
    line-height: 130%;
  }
  .desc-search-result {
    font-family: $font-family-base;
    font-size: 0.8rem;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: .07rem;
    margin-top: 0;
    line-height: 130%;
  }

  // Form Search
  .form-control-search {
    font-family: $font-family-base;
    height: calc(1.6em + .75rem + 2px)!important;
    color: $grey !important;
    background-color: $white !important;
    background-clip: padding-box;
    border: 1px solid $sand-wash;
    letter-spacing: -0.25px;
    font-weight: 400;
    font-size: 0.85rem !important;
    padding-left: 1.5rem !important;
  }
  .input-group-append {
    width: 100%;
    margin-left: auto !important;
    margin: auto;
    margin-top:15px;
    @media #{$sm-up}{
      width: 30%;
      margin-top: 15px;
    }
    @media #{$md-up}{
      width: auto;
      margin-top: 0;
    }
    .btn-green {
      width: 100%;
      min-width: 120px;
    }
  }
  .input-group-lg>.form-control {
    width: 100%;
    height: 50px !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 4px !important;
    margin-right: 0;
    @media #{$md-up}{
      width: 60%;
      margin-right: 10px;
      height: 50px !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }

  // Settings Input Browser
  input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
  input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }
}
