.hero {
  position: relative;
  height: 580px;
  margin-top: 0;
  @media #{$md-up} {
    height: 680px;
  }

  &_background {
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;

    &-image {
      margin: auto;
      height: 100%;
      width: 100%;
      border-radius: 0;
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  &_content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    top: 300px;
    width: 100%;
    position: absolute;
    @media #{$md-up} {
      top: 340px;
    }
    @media #{$lg-up} {
      top: 340px;
    }

    &-title {
      font-family: $font-family-title;
      color: $white;
      font-size: 40px;
      line-height: 48px;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: -2px;
      text-align: center;
      transition: all .5s ease-in-out;
      @media #{$md-up} {
        font-size: 64px;
        line-height: 72px;
      }
      @media #{$lg-up} {
        font-size: 72px;
        line-height: 80px;
      }
      @media #{$xl-up} {
        font-size: 72px;
        line-height: 80px;
      }
    }

    &-subtitle {
      color: $white;
      font-family: $font-family-base;
      font-weight: $font-weight-normal;
      text-align: center;
      max-width: 85%;
      margin: 15px auto;
      font-size: 1.25rem;
      margin-top: 20px;
      line-height: 1.75rem; /* 140% */
      transition: all .5s ease-in-out;

      @media #{$lg-up} {
        font-size: 1.25rem;
        line-height: 1.75rem; /* 140% */
        max-width: 55%;
        margin: 15px auto;
      }
    }
  }
}
.template-homepage {
  .hero {
    max-width: 100%;
    position: relative;
    height: 580px;
    margin-top: 0;
    background: $sand;
    @media #{$md-up} {
      height: 680px;
    }

    &_background {
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      background: $sand;
      @media #{$md-up} {
        top: 68px
      }
      @media #{$lg-up} {
        top: 68px
      }
      @media #{$xl-up} {
        top: 68px
      }

      &-image {
        margin: auto;
        background-color: $sand;
        height: 100%;
        width: 100%;
        border-radius: 0;
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
        transition: width 0.3s ease;
        @media #{$md-up} {
          width: 94%;
          border-radius: 4px;
        }
      }
    }

    &_content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      top: 300px;
      width: 100%;
      position: absolute;
      @media #{$md-up} {
        top: 440px;
      }
      @media #{$lg-up} {
        top: 380px;
      }

      &-title {
        font-family: $font-family-title;
        color: $white;
        font-size: 40px;
        line-height: 48px;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: -2px;
        text-align: center;
        transition: all .5s ease-in-out;
        @media #{$md-up} {
          font-size: 64px;
          line-height: 72px;
        }
        @media #{$lg-up} {
          font-size: 72px;
          line-height: 80px;
        }
        @media #{$xl-up} {
          font-size: 72px;
          line-height: 80px;
        }
      }

      &-subtitle {
        color: $white;
        font-family: $font-family-base;
        font-weight: $font-weight-normal;
        text-align: center;
        font-size: 1.25rem;
        margin-top: 20px;
        line-height: 1.75rem; /* 140% */
        transition: all .5s ease-in-out;

        @media #{$lg-up} {
          font-size: 1.25rem;
          line-height: 1.75rem; /* 140% */
        }
      }
    }
  }
}
