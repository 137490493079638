.btn-primary {
  font-family: $font-family-title;
  width:fit-content;
  text-transform: uppercase;
  height: 48px;
  color: $black;
  padding:  8px 12px;
  font-size: 14px;
  white-space: nowrap;
  border-radius: 4px;
  line-height: 32px;
  border: 1px solid  $black;
  background: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $white;
    text-transform: uppercase;
    border: 1px solid  $black;
    background: $black;
    transition: all 0.3s ease-in-out;
  }
}

// Button Outline green
.btn-secondary {
  font-family: $font-family-title;
  width:fit-content;
  text-transform: uppercase;
  height: 48px;
  white-space: nowrap;
  color: $viridian;
  padding:  8px 12px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 32px;
  border: 1px solid  $viridian;
  background: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $white;
    text-transform: uppercase;
    border: 1px solid  $viridian;
    background: $viridian;
    transition: all 0.3s ease-in-out;
  }
}
// Button Outline green with icon plus
.btn-secondary-more {
  font-family: $font-family-title;
  width: fit-content;
  text-transform: uppercase;
  height: 48px;
  white-space: nowrap;
  color: $viridian;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 32px;
  border: 1px solid $viridian;
  background: none;
  transition: all 0.3s ease-in-out;
  position: relative;
  min-width: 135px;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: space-evenly;
  &:hover {
    color: $white;
    text-transform: uppercase;
    border: 1px solid $viridian;
    background: $viridian;
    transition: all 0.3s ease-in-out;

    /* Changez l'image au survol */
    &:after {
      background-image: url('/public/img/icons2024/plus-white.svg'); /* Changez le chemin vers votre nouvelle image */
    }
  }

  &:after {
    content: '';
    width: 16px;
    height: 16px;
    background-image: url('/public/img/icons2024/plus-green.svg');
    background-size: cover;
    transition: all 0.3s ease-in-out;
  }
}

// Button Outline green with icon minus
.btn-secondary-less {
  font-family: $font-family-title;
  width: fit-content;
  text-transform: uppercase;
  height: 48px;
  white-space: nowrap;
  color: $viridian;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 32px;
  border: 1px solid $viridian;
  background: none;
  transition: all 0.3s ease-in-out;
  position: relative;
  min-width: 135px;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: space-evenly;
  &:hover {
    color: $white;
    text-transform: uppercase;
    border: 1px solid $viridian;
    background: $viridian;
    transition: all 0.3s ease-in-out;

    &:after {
      background-image: url('/public/img/icons2024/minus-white.svg');
    }
  }

  &:after {
    content: '';
    width: 16px;
    height: 16px;
    background-image: url('/public/img/icons2024/minus-green.svg');
    background-size: cover;
    transition: all 0.3s ease-in-out;
  }
}

// Button Outline White
.btn-white {
  font-family: $font-family-title;
  width:fit-content;
  text-transform: uppercase;
  height: 48px;
  white-space: nowrap;
  color: $white;
  padding:  8px 12px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 32px;
  border: 1px solid  $white;
  background: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $black;
    text-transform: uppercase;
    border: 1px solid  $white;
    background: $white;
    transition: all 0.3s ease-in-out;
  }
}
// Button Text Green BG white
.btn-white-full {
  font-family: $font-family-title;
  width:fit-content;
  white-space: nowrap;
  text-transform: uppercase;
  height: 48px;
  color: $viridian;
  padding:  8px 12px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 32px;
  background: $white;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $viridian;
    text-transform: uppercase;
    background: $white;
    transition: all 0.3s ease-in-out;
  }
}

// Button Bg Green
.btn-green {
  font-family: $font-family-title;
  width:fit-content;
  text-transform: uppercase;
  height: 48px;
  color: $white;
  padding:  8px 12px;
  font-size: 14px;
  white-space: nowrap;
  border-radius: 4px;
  line-height: 32px;
  border: 1px solid  $viridian;
  background: $viridian;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $white;
    text-transform: uppercase;
    border: 1px solid  $viridian;
    background: $viridian;
    transition: all 0.3s ease-in-out;
  }
}

.btn-type {
  padding:2px 24px !important;
  outline: none !important;
}
.bg-carbon {background:$carbon!important;}
.bg-pine {background:$pine!important;}
.bg-sand {background:$sand!important;}
.bg-grey {background:$grey!important;}
.bg-mint {background:$mint!important;}
.bg-mint-light {background:$mint-light !important;}
.bg-rose {background:$rose!important;}
.bg-rose-light {background:$rose-light !important;}
.bg-sand-light {background:$sand-light!important;}
.bg-blue-light {background:$blue-light!important;}
.bg-sand-wash {background:$sand-wash!important;}
.bg-opacity {background-color: #ffffffa6 !important;}
.bg-viridian { background:$viridian !important;}

.btn-sand {
  font-family: $font-family-title;
  width: fit-content;
  text-transform: uppercase;
  height: 48px;
  color: $viridian;
  padding: 8px 12px;
  font-size: 14px;
  white-space: nowrap;
  border-radius: 4px;
  line-height: 32px;
  border: 1px solid $viridian;
  background: $sand-wash;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: $viridian;
    text-transform: uppercase;
    border: 1px solid $viridian;
    background: $sand-wash;
    transition: all 0.3s ease-in-out;
  }
}
