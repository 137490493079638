// Navbar by default >
// Definition of the default navigation bar
.hero_navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background: transparent;
  margin: auto;
  padding: 0;
  z-index: 1030;

  // Navigation bar styles
  .navbar {
    margin: 0 !important;
    padding: 0 !important;
    height: 64px;
    align-items: center !important;
    justify-content: end;
    padding: 1.5rem 2rem;

    &-nav {
      padding-left: 0;
      margin: auto;
    }

    // Right-aligned navigation bar style
    &-right {
      position: absolute;
      right: 1.5rem;
      display: flex;
      height: 64px;
      align-items: center;
      gap: 1.25rem;
      @media #{$md-up} {
        right: 2rem;
      }
    }
  }

  // Navigation links styles
  .nav-link {
    position: relative;
    font-family: $font-family-title;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    margin: 2rem 0 0 0;
    font-weight: $font-weight-normal;
    font-size: 1.5rem;
    color: $viridian;
    z-index: 11;
    line-height: 2rem;
    white-space: nowrap;
    @media #{$lg-up} {
      font-size: 15px;
      color: white;
      line-height: 20px;
      margin: 0 0 0 5px;
    }
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      color: transparent;
      width: 0;
      content: '';
      height: 1px;
      background-color: $viridian;
      transition: all 0.5s;
      @media #{$md-up} {
        height: 1px;
        background-color: $viridian;
      }
      @media #{$lg-up} {
        height: 1px;
        background-color: white;
      }
      @media #{$xl-up} {
        height: 1px;
        background-color: white;
      }
    }
    &:hover::after {
      width: 100%;
      border: 0;
    }
    &.active {
      color: $viridian;
      @media #{$lg-up} {
        color:white;
      }
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color:$viridian;
        color: transparent;
        width: 100%;
        content: '';
        height: 0;
        transition: all 0.5s;
        @media #{$lg-up} {
          height: 1px;
          background-color: white;
        }
      }
    }
  }

  // Fullscreen menu styles
  .fullscreen-menu {
    width: 100% !important;
    max-width: none !important;
  }

  // Styles for the body of the fullscreen menu
  .offcanvas-body {
    flex-grow: 1;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  // Styles for the fullscreen menu when displayed
  .offcanvas.show:not(.hiding),
  .offcanvas.showing {
    transform: none;
    background: $sand;
  }

  // Styles for the header of the fullscreen menu
  .offcanvas-header {
    height: 64px;
    padding: 1.5rem 1.5rem;
    @media #{$lg-up} {
      padding: 1.5rem 2rem;
    }
    .logo {
      height:25px;
    }

    .btn-close {
      background-image: url("/public/img/icons/svg/close-cross.svg");
      width: 16px;
      height: 16px;
      background-size: 20px;
      background-repeat: no-repeat;
      outline: none;
      box-shadow: none;
      opacity: 1;
    }
  }
  // Avoid Outline of Search button
  .btn:focus,
  .btn.focus {
    outline: none !important;
    box-shadow: none !important;
    border: 0;
  }

  // Search button padding
  .search {
    border: 0;
    background: none;
    padding: 0;
    @media #{$lg-up} {
      padding: 0;
    }
  }

  // Styles for the navbar toggle button
  .navbar-toggler {
    border: none;
    padding:0;
    @media #{$lg-up} {
      padding: 0;
    }

    &:focus {
      text-decoration: none;
      outline: 0;
      box-shadow: none;
    }

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      width: 16px;
      height: 16px;
      background-size: 24px;
      background-repeat: no-repeat;
    }
  }

  // Dropdown language styles
  .dropdown {
    .dropdown-toggle {
      cursor: pointer;
      background: none !important;
      border: none !important;
      padding: 1.5rem 0;
      margin: 0 !important;
      outline: none !important;
      box-shadow: none;
      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      display: none;
      opacity: 0;
      width: fit-content;
      min-width: fit-content;
      border: none;
      background: $sand;
      border-radius: 4px;
      padding: 0.5rem 0.25rem;
      left: -37px;
      top: 61px;
      box-shadow: 0 0 15px 2px #6c6c6c54;
      transition: all 200ms ease-in;

      .dropdown-item {
        padding: .25rem 0.85rem;
        color: $black;

        &:focus,
        &:hover {
          color: $black;
          background: $sand;
        }

        .active,
        &:active {
          color: $black;
          background: $sand;
        }
      }
    }
    .dropdown-menu.show  {
      display: block;
      background: $sand;
      opacity: 1;
      width: fit-content;
      min-width: fit-content;
      transition: all 200ms ease-in;
    }
  }

  // Mega Menu Destination
  .offcanvas-destination {
    .offcanvas.show:not(.hiding),
    .offcanvas.showing {
      transform: none;
      height: fit-content;
      background: $white;
    }
    .offcanvas-body {
      padding:0.25rem 2rem 2rem 2rem;
    }
    .navbar-nav {
      position: absolute;
      flex-direction: row;
      padding-left: 0;
      left: 50%;
      display: none;
      transform: translateX(-50%);
      margin: auto;
      margin-bottom: 0;
      @media #{$lg-up} {
        display: flex
      }
    }
    .nav-link {
      color: black;
      margin: 0 0 0 25px;
      &::after {
        background-color: black;
        color: black;
      }
      &.active {
        color: black;
        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          background-color: #000;
          color: transparent;
          width: 100%;
          content: '';
          height: 1px;
          transition: all 0.5s;
        }
      }
    }

    .navbar-right {
      right: 2.5rem;
    }

    .navbar-toggler {
      display: block;
      @media #{$lg-up} {
        display: none;
      }
    }
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
    }
    .btn-close {
      padding:0;
      display: none;
      @media #{$lg-up} {
        display: block;
      }
    }
  }
  .offcanvas-backdrop {
    background-color: #F5F1EF;
    &.show { opacity: 0.8; }
  }
  // Container Select Destination
  .menu-destination—content {
    z-index: 3;
    margin: auto;
    right: 0;
    padding: 1.5rem;
    border-radius: 4px;
    background-color: $sand-wash;
    width: 100%;

    .destination_zone-container {
      width: 100%;
      margin: auto;
      flex-wrap: wrap;
      gap: 2rem;
      display: flex;
      padding: 0;
    }

    .destination_zone-one {
      @media #{$sm-up} {
        flex: 0 0 auto;
        width: 45%;
      }
      @media #{$md-up} {
        flex: 0 0 auto;
        width: 65%;
      }
      @media #{$xl-up} {
        flex: 0 0 auto;
        width: 65%;
      }
    }
    .destination_zone-two {
      @media #{$sm-up} {
        flex: 0 0 auto;
        width: 45%;
      }
      @media #{$md-up} {
        flex: 0 0 auto;
        width: 30%;
      }
      @media #{$xl-up} {
        flex: 0 0 auto;
        width: 32%;
      }
    }
    ul { padding-left: 0; margin-bottom: 0}
    li {
      padding:0; margin:0; list-style: none;
      span {
        font-family: $font-family-base;
        font-size: .875rem;
        letter-spacing: 0;
        font-style: normal;
        color: $grey;
      }
    }
    .destination_zone_title {
      //Eg: FRENCH ALPS / SPAIN / SUISSE
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      color:$viridian;
      font-family: $font-family-base;
      line-height: 1rem; /* 133.333% */
      letter-spacing: 0.06rem;
      text-transform: uppercase;
      width: 100%;
      margin-bottom: 1rem;
      border-bottom: 1px solid $sand;
      transition: all 0.3s linear;
    }

    .destination-area {
      //Eg: CHAMONIX ALPD'HUEZ ESPACE KILLY
      font-family:$font-family-title;
      display: inline-block;
      width: 100%;
      padding-bottom: 1.25rem;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      letter-spacing: 0.07rem;
      text-transform: uppercase;

      &-list {
        //Eg: Châtel Les gets
        font-family: $font-family-base;
        font-size: .875rem;
        letter-spacing: 0;
        font-style: normal;
        padding-top: 0.5rem;
        text-transform: none;
        padding-bottom: 0;
        padding-left: 1.25rem;
        font-weight: 400;
        line-height: 1.25rem;
      }
    }
    .btn-close {
      border: none;
      position: absolute;
      right: 10px;
      top: 10px;
      outline: none;
      background: none;
      img {
        width: 22px;
      }
    }
    p {
      font-family: $font-family-base;
      font-weight: 400;
      font-size: 15px;
      span {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        color: #a2a2a2;
      }
    }
  }
}

//  Style Navbar Brand of Template
.template-page, .template-propertyList, .template-destination, .template-property-management,  .template-blogList-home, .template-blogList-category, .template-team_page, .template-sitemap, .template-blogPost, .template-property,
.template-newsletter, .template-404 {
  .hero_navbar {
    .navbar-brand {
      img {
        position: absolute;
        width: 200px;
        top: 18px;
        left:18px;
        transform: translateY(0px);
        @media #{$lg-up} {
          left:34px;
        }
      }
    }
  }
}

// Template-Homepage Navbar color + animation
.template-homepage {
  .hero_navbar {
    background: transparent;
    @media #{$md-up} {
      // Change color of the background and fixed the position
      position: fixed;
      background: $sand;
      top: 0;
      left: 0;
      transition: background 0.4s;
    }
    .navbar-brand {
      img {
        position: absolute;
        width: 200px;
        top: 18px;
        left:18px;
        transform: translateY(0px);
        @media #{$lg-up} {
          left:34px;
        }
      }
    }
    // Change color of toggler Icon in use
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      @media #{$md-up} {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      }
    }
  }
}

// Template-Property Navbar color
.template-property {
  .hero_navbar {
    // Changed color of background
    background: $sand;
    // Change color of toggler Icon in use
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }
}

//Template blog list home Navbar color
.template-blogList-home {
  .hero_navbar {
    background: $sand;
    padding: 0 !important;
    // Change color of toggler Icon in use
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }
}

//Template Team Navbar color
.template-team_page {
  .hero_navbar {
    background: $sand-wash;
    padding: 0 !important;
    // Change color of toggler Icon in use
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }
}

// Template Blogpost Navbar color
.template-blogPost {
  .hero_navbar {
    background: white;
    padding: 0 !important;
    // Change color of toggler Icon in use
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }
}

// Blog List Category Navbar color
.template-blogList-category {
  .hero_navbar {
    background: $white;
    padding: 0 !important;
    // Change color of toggler Icon in use
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }
}
//Template sitemap Navbar color
.template-sitemap {
  .hero_navbar {
    background: $sand-wash;
    padding: 0 !important;
    // Change color of toggler Icon in use
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }
}

// Change link colors on page without hero-image
.template-sitemap, .template-blogList-category, .template-blogPost, .template-blogList-home, .template-homepage, .template-property, .template-team_page {
  .hero_navbar {
    .nav-link {
      color: $viridian;
      @media #{$lg-up} {
        color: black;
      }
      &::after {
        background-color: $viridian;
        color:$viridian;
        @media #{$lg-up} {
          background-color: black;
          color:black;
        }
      }
    }
  }
}

// Change link colors on page with hero-image
.template-destination, .template-propertyList, .template-page, .template-property-management, .template-newsletter, .template-404 {
  .hero_navbar {
    .nav-link {
      color: $viridian;
      @media #{$lg-up} {
        color: white;
      }
      &::after {
        background-color: $viridian;
        color: $viridian;
        @media #{$lg-up} {
          background-color: white;
          color: white;
        }
      }

      &.active {
        color: $viridian;
        @media #{$lg-up} {
          color: white;
        }

        &::after {
          background-color: $viridian;
          color: $viridian;
          @media #{$lg-up} {
            background-color: white;
            color: white;
          }
        }
      }
    }
  }
}
