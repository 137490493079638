
// Page newletter.ejs
  .template-newsletter {
  background-size: cover;
  height: 100vh;
  main {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section_newletter{
    text-align: center;
    background-color: white;
    width: fit-content;
    height: fit-content;
    max-height: fit-content;
    min-height: fit-content;
    border-radius: 5px;
    padding: 1rem 1.5rem;
    margin:auto;

    a {
      color: black;
    }
    a:hover{
      border-bottom: 1px solid #000;
    }
  }
  h1 {
    font-size: 37px;
    font-family: $font-family-title !important;
    font-weight: 400;
    color: black;
  }
  .hs-button {
    font-family: $font-family-title !important;
    width:fit-content !important;
    text-transform: uppercase !important;
    height: 48px !important;
    color: #fff !important;
    padding:  8px 12px !important;
    font-size: 14px !important;
    white-space: nowrap !important;
    border-radius: 4px !important;
    line-height: 32px !important;
    background: $viridian !important;
    transition: all 0.3s ease-in-out !important;
  }
  .hs-form-booleancheckbox-display input[type=checkbox] {
    border-radius: 2px !important;
  }
  .hs-form-booleancheckbox-display input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0.999512L3.5 6.49951L1 3.99951' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
    background-size: 11px !important;
  }
  .hs-form-booleancheckbox-display input:checked {
    background-color: $viridian !important;
    border-color: $viridian  !important;
    border-radius: 2px !important;
  }
  .hs-error-msg {
    color: red;
  }
}

// Section Newletter component in footer
  .section_newletter {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 60px 20px;
  @media #{$md-up}{
    max-height: 400px;
    padding: 80px 40px;
  }
  .newsletter {
    .title {
      font-family: $font-family-title !important;
      font-weight: 400;
      font-size: 36px;
      line-height: 64px;
      text-align: center;
      text-transform: uppercase;

      @media #{$md-up} {
        font-size: 56px;
        line-height: 64px;
      }
      @media #{$lg-up} {
        font-size: 56px;
        line-height: 64px;
      }
    }
  }


  // HUBSPOT formating form email
  .hs_recaptcha {
    visibility: hidden;
    position: absolute;
    left: 0;
    bottom: 30%;
  }

  .hbspt-form {
    background: none;
    font-family: $font-family-base;
    font-weight: 300;
    padding-bottom: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;

    form {
      ul {
        padding: 0;
        margin: 0;
      }

      li {
        list-style: none;
      }

      //Part email
      .hs_email {
        order: 1;

        label {
          margin-bottom: 0;
          text-align: left;
          font-weight: 300;
        }

        ul {
          padding: 0;
          margin: 0;
        }

        .hs-input {
          width: 100%;
          align-self: center;
          background: #F5F1EF;
          border-radius: 4px;
          height: 35px;
          outline: none;
          padding: 0 5px;
          font-weight: 300;
          border: 1px solid #EFE7E3;
          @media #{$md-up} {
            width: 100%;
          }
        }
      }

      //parts consent
      .legal-consent-container {
        padding: 5px 0;

        .hs-form-booleancheckbox-display {
          input {
            margin-top: 0;
            outline: none;
          }

          span {
            font-size: 11px;
            line-height: 1.2 !important;
            text-align: center;

            p {
              margin: 0;
              font-size: 11px;
              line-height: 1.2 !important;
              text-align: center;
            }
          }
        }

        ul {
          padding: 0;
          margin: 0;
        }
      }

      // part btn submit
      .hs_submit {
        height: 35px;
        margin-top: -10px;

        .hs-button {
          font-family: $font-family-title;
          width:fit-content;
          text-transform: uppercase;
          height: 48px;
          color: $white;
          padding:  8px 12px;
          font-size: 14px;
          white-space: nowrap;
          border-radius: 4px;
          line-height: 32px;
          border: 1px solid  $viridian;
          background: $viridian;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: $white;
            text-transform: uppercase;
            border: 1px solid  $viridian;
            background: $viridian;
            transition: all 0.3s ease-in-out;
          }
        }
      }

      .hs_error_rollup {
        display: none;
      }

      .hs-error-msg {
        font-size: 11px;
      }
    }
  }

  nav {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-top: 0;
    margin: 0 auto;
    align-items: center;
    @media #{$md-up}{
      padding-top: 0;
    }
    .social-media {
      width: 200px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

// Footer with accordion
  footer {
    background-color: $black;
    padding:  40px 20px 80px 20px;
    @media #{$lg-up}{
      padding: 40px 40px 80px 40px;
    }
    .footer-columns, .footer-row {
      max-width: 1440px;
      display:flex;
      margin: auto !important;
    }
    .footer-brand {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 0;
      margin-bottom: 1rem;
      @media #{$md-up}{
        justify-content: start;
        margin-top: 20px;
      }
      @media #{$lg-up}{
        justify-content: start;
        margin-top: 0;
      }
      img {
        height: 60%;
        @media #{$md-up}{
          height: fit-content;
        }
      }
    }
    .footer-column {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      @media #{$md-up}{
        margin-top: 0;
      }
      .accordion {
        --bs-accordion-color: white;
        --bs-accordion-bg: transparent;
      }
      .accordion-header {
        border-top: 1px solid #858383;
        padding-top: 8px;
        margin: 0;
        @media #{$lg-up}{
          border-top: 0;
        }
      }
      .accordion-button {
        width: 100%;
        padding:0;
        color: white;
        border:0;
        cursor: pointer !important;
        background-color: transparent;
        font-family: $font-family-title !important;
        font-weight: 400;
        font-size: 22px !important;
        text-transform: uppercase;
        line-height: 32px;
        margin-bottom: 8px;
        outline: none;
        box-shadow: none;
        text-align: left;
        @media #{$lg-up}{
          cursor: default !important;
        }
        //Custom accordion icon bootstrap + show in tablet/mobile
        &:after {
          display: block;
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 8H12.6668' stroke='white' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 3.33331V12.6666' stroke='white' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          @media #{$lg-up}{
            display: none;
          }
        }
        &:not(.collapsed):after {
          display: block;
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 8H12.6668' stroke='white' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          transform: none;
          @media #{$lg-up}{
            display: none;
          }
        }
        &:not(.collapsed) {
          color:white;
          background-color: transparent;
          box-shadow: none;
        }
        &:focus {
          border-color: transparent;
          outline: none;
          box-shadow: none;
        }
      }
      .accordion-body {
        padding: 0;
        cursor: default !important;
        display: flex;
        flex-direction: column;
        padding-top: 8px;
        padding-bottom: 24px;
        @media #{$lg-up}{
          padding-top: 0;
          padding-bottom: 0;
        }
        ul {
          padding-left: 0;
          margin-bottom: 0;
          padding-bottom: 0;
          li {
            list-style: none;
          }
        }
      }
      .accordion-link {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: 400;
        text-decoration: none;
        color: white;
        font-size: 16px;
        line-height:32px;
        @media #{$md-up}{
          font-size: 16px;
          line-height: 24px;
          text-align: left;
        }
        @media #{$lg-up}{
          font-size: 16px;
          text-align: left;
        }
      }
    }
    .footer-legal {
      color: white;
      font-family: $font-family-base;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin-top: 10px;
      text-align: center;
      line-height: 18px;
      @media #{$lg-up}{
        text-align: left;
        margin-top: 0;
      }
      a {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: 400;
        text-decoration: none;
        color: white;
        font-size: 16px;
        line-height: 24px;
        @media #{$md-up}{
          font-size: 16px;
          line-height: 24px;
        }
        &:hover {
          color: white;
          font-weight: 400;
        }
      }
    }
    .footer-language {
      color: white;
      text-align: center;
      @media #{$lg-up}{
        text-align: left;
      }
      .lang {
        border-bottom: none;
        &.active {
          border-bottom: 1px solid white;
        }
      }
      a {
        text-decoration: none;
        color: white;
        font-size: 16px;
        line-height: 24px;
        &:hover {
          color: white;
          font-weight: 400;
        }
      }
    }
  }
