//General Typography

a {
  text-decoration: none;
  color:$link-color;
}


p {
  a {
    text-decoration: underline;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2 {
  font-family: $font-family-title;
}

.heading_title-h1 {
  font-family: $font-family-title;
  font-size: 72px;
  text-transform: uppercase;
}

.heading_section-title {
  font-family: $font-family-base;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  line-height: 16px; /* 114.286% */
  letter-spacing: 1.12px;
  font-weight: 400;
  margin-bottom:32px !important;
}

.text-green {color: $viridian;}
.text-white {color: $white;}
.text-black {color: $black;}

p {
  font-family: $font-family-base;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

$font-weight-bolder:600;

b, strong {
  font-weight: 600;
}


