@import "vendor/bootstrap";
@import "vendor/slick";
@import "base/global";
@import "layout/global";
@import "component/search_modal";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  &.template-homepage {
    overflow-x: hidden;
  }
}

// All common component use in content-type

// Destination Manage Header in use Property Management
.destination_manage_header {
  .container {
    max-width: 1440px;
    display: flex;
    padding: 64px 24px;
    flex-wrap: wrap;
    @media #{$lg-up} {
      padding: 80px 40px;
    }
  }
  &-column-text {
    flex: 0 0 auto;
    width: 100%;
    padding-bottom:48px;
    padding-right: 44px;
    @media #{$md-up} {
      padding-bottom:0;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .title {
      font-family:$font-family-title;
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      text-transform: uppercase;
      margin:0;
      @media #{$md-up} {
        font-size: 38px;
        line-height: 44px;
      }
      @media #{$lg-up} {
        font-size: 50px;
        line-height: 60px;
      }
      @media #{$xl-up} {
        font-size: 56px;
        line-height: 64px;
      }
    }
    .content-text {
      @media #{$md-up} {
        max-width: 350px;
      }
      @media #{$lg-up} {
        max-width: 450px;
      }
    }
    .tagline {
      font-family: $font-family-base;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 140% */
      padding-top: 24px;
      padding-bottom: 24px;
      margin:0;
      @media #{$md-up} {
        font-size: 18px;
        line-height: 26px;
      }
      @media #{$lg-up} {
        font-size: 20px;
        line-height: 28px;
      }
    }

    .description {
      padding-bottom: 32px;
      @media #{$md-up} {
        padding-bottom: 40px;
      }
      p {
        font-family: $font-family-base;
        font-size: 16px;
        line-height: 24px;
        @media #{$md-up} {
          font-size: 14px;
          line-height: 22px;
        }
        @media #{$lg-up} {
          font-size: 16px;
          line-height: 24px;
        }
      }
      h3 {
      font-family: $font-family-title;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      margin: 0;
      padding-bottom: 16px;
      text-transform: uppercase;
    }
    }
  }

  &-column-image {
    flex: 0 0 auto;
    width: 100%;
    @media #{$md-up} {
      width: 50%;
    }
  }
}

// Section Properties spotlight (not automatic) in use > Destination / Property Management
.section_properties_spotlight {
  text-align: center;
  .heading_section-title {
    @media #{$md-up} {
      margin-bottom: 20px;
    }
  }
  a {cursor: pointer;}
  .container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 64px 0;
    @media #{$md-up} {
      padding: 80px 0;
    }
  }
  h3 {
    font-family:$font-family-title;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin: 0;
    @media #{$md-up} {
      font-size: 34px;
      line-height: 46px;
    }
    @media #{$lg-up} {
      font-size: 40px;
      line-height: 48px;
    }
  }
  .displayTagText{
    p { // SEO option h3
      font-family:$font-family-title;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      margin: 0;
      @media #{$md-up} {
        font-size: 34px;
        line-height: 46px;
      }
      @media #{$lg-up} {
        font-size: 40px;
        line-height: 48px;
      }
    }
    h2 { // SEO option h3
      font-family:$font-family-title;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      margin: 0;
      @media #{$md-up} {
        font-size: 34px;
        line-height: 46px;
      }
      @media #{$lg-up} {
        font-size: 40px;
        line-height: 48px;
      }
    }
  }
  &_grid {
    display: flex;
    justify-content: start;
    padding: 24px 30px 20px 30px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    @media #{$md-up} {
      padding: 80px 40px 0 40px;
      justify-content: center;
    }
    &-card {
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
      padding: 10px;
      background: none;
      scroll-snap-align: start;
      transition: all 0.2s;
      flex-basis: calc(100% - 10px);
      @media #{$sm-up} {flex-basis: calc(75% - 20px); padding: 15px;}
      @media #{$md-up} {flex-basis: calc(50% - 20px); padding: 15px;}
      @media #{$lg-up} {flex-basis: calc(35% - 15px);}
      @media #{$xl-up} {flex-basis: calc(35% - 30px);}
    }
    &-card:first-child {
      margin-left: 20px;
      @media #{$md-up} { margin-left: 30px;}
      @media #{$lg-up} { margin-left: 10px; }
    }

    &-card:not(:last-child) {
      @media #{$md-up} { }
      @media #{$lg-up} { }
    }
  }
  &_grid::-webkit-scrollbar-thumb,
  &_grid::-webkit-scrollbar-track {
    border-radius: 92px;

  }
  &_grid::-webkit-scrollbar-thumb {
    background: $greydark;
  }
  &_grid::-webkit-scrollbar-track {
    background: #FAFAFA;
  }
  &_grid::-webkit-scrollbar {
    height: 1px;
    @media #{$md-up} {
      height: 0;
    }
  }
  .title {
    padding: 20px 0 8px;
    font-family:$font-family-title;
    text-transform: uppercase;
    font-size: 22px;
    margin-bottom: 0;
    line-height:28px;
    text-align: left;
    font-weight: 400;
    @media #{$md-up} {
      font-size: 26px;
      line-height:32px;
    }
    @media #{$lg-up} {
      font-size: 32px;
      line-height:40px;
    }
  }
  .description {
    text-align: left;
    margin: 0;
    @media #{$md-up} {
    }
    @media #{$lg-up} {
    }
  }
  .modalComingSoon {
    .modal-content {
      border-radius: 0.25rem;
      padding: 1.5rem;
      border: none;
    }
    .modal-body {
      text-align: left;
    }
    .modal-header {
      border: none;
      color:#000;
      background: $sand-wash;
      .modal-title {
        font-family: $font-family-title;
        font-weight: 400;
        font-size: 1.5rem;
        font-style: normal;
        line-height: 1.5rem; /* 133.333% */
        text-transform: uppercase;
      }

      .btn-close {
        background-image: url(/public/img/icons/svg/close-cross.svg);
        width: 16px;
        height: 16px;
        background-size: 20px;
        background-repeat: no-repeat;
        outline: none;
        box-shadow: none;
        opacity: 1;
      }
    }
    .modal-footer {
      border: none;
      padding: 1.5rem 1rem 0 1rem;
    }
  }
}

// Manage toogle Infos in use Property Management/Homeowner
.destination_manage_toggle_infos {
  .container {
    max-width: 1440px;
    display: flex;
    padding: 64px 24px;
    flex-wrap: wrap;
    @media #{$lg-up} {
      padding: 80px 40px 120px;
    }
  }

  .column-title {
    width: 100%;
    flex: 0 0 auto;
  }
  .column-accordion {
    width: 100%;
    flex: 0 0 auto;
    @media #{$md-up}{
      width: 100%;
    }
    @media #{$lg-up}{
      width: 75%;
    }
    @media #{$xl-up}{
      width: 66.66667%;
    }
  }
  h2 {
    font-family: $font-family-title;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    line-height: 32px;
    text-transform: uppercase;
    padding-bottom: 32px;
    max-width: 100%;
    @media #{$md-up} {
      font-size: 40px;
      line-height: 48px;
      padding-bottom: 80px;
      max-width: 95%;
    }
    @media #{$lg-up} {
      font-size: 40px;
      line-height: 48px;
      max-width: 95%;
    }
    @media #{$xl-up} {
      font-size: 40px;
      line-height: 48px;
      max-width: 70%;
    }
  }

  .accordion {
    --bs-accordion-color: black;
    --bs-accordion-bg: transparent;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }
  .accordion-item {
    margin: 0;
    flex: 0 0 auto;
    padding-bottom: 24px;
    border-bottom: 1px solid $greydark;
    padding-top: 24px;
    width: 100%;
    @media #{$md-up}{
      width: 50%;
      padding-bottom: 64px;
      padding-left: 0;
      padding-right: 32px;
      padding-top: 0;
      border: 0;
    }
    @media #{$lg-up}{
      padding-left: 16px;
      padding-right: 16px;
    }
    &:first-child {
      border-top: 1px solid $greydark !important;
      @media #{$md-up}{
        border: 0 !important;
      }
    }
    &:last-child {
      border-bottom: 1px solid $greydark !important;
      @media #{$md-up}{
        border: 0 !important;
      }
    }
  }
  .accordion-header {
    padding: 0;
    margin: 0;
    @media #{$md-up}{
      border-top: 0;
    }
  }
  .accordion-button {
    width: 100%;
    padding:0;
    color: black;
    border:0;
    align-items: center;
    cursor: pointer !important;
    background-color: transparent;
    margin: 0;
    outline: none;
    box-shadow: none;
    text-align: left;
    @media #{$md-up}{
      align-items: start;
      cursor: default !important;
    }
    h3 {
      font-family: $font-family-title !important;
      margin:0;
      padding: 0;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-transform: uppercase;
      @media #{$md-up} {
        font-size: 24px;
        line-height: 32px;
      }
      b, strong {
        font-weight: 400;
      }
    }

    //Custom accordion icon bootstrap + show in mobile
    &:after {
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 8H12.6668' stroke='black' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 3.33331V12.6666' stroke='black' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      @media #{$md-up}{
        display: none;
      }
    }
    &:not(.collapsed):after {
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 8H12.6668' stroke='black' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      transform: none;
      @media #{$md-up}{
        display: none;
      }
    }
    &:not(.collapsed) {
      color:black;
      background-color: transparent;
      box-shadow: none;
    }
    &:focus {
      border-color: transparent;
      outline: none;
      box-shadow: none;
    }
  }
}

// Component Banner call_to_action_button in use
.call_to_action_button {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  .container {
    max-width: 1440px;
    display: flex;
    padding: 64px 24px;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    @media #{$lg-up} {
      padding: 80px 40px;
    }

    .title {
      font-family: $font-family-title;
      font-size: 32px;
      line-height: 40px;
      font-style: normal;
      font-weight: 400;
      text-align: center;
      margin: 0;
      max-width: 95%;
      text-transform: uppercase;
      @media #{$md-up} {
        max-width: 95%;
      }
      @media #{$lg-up} {
        max-width: 80%;
        font-size: 56px;
        line-height: 64px;
      }
      @media #{$xl-up} {
        max-width: 70%;
      }
    }
    .description {
      text-align: center;
      margin: 0;
      padding-top: 32px;
      font-size: 16px;
      line-height: 24px;
      font-style: normal;
      font-weight: 400;
      max-width: 95%;
      @media #{$lg-up} {
        max-width: 80%;
      }
      @media #{$xl-up} {
        max-width: 70%;
      }
    }
    button {
      margin-top: 20px;
    }
  }
  //if block multiple
  .block-description {
    text-align: center;
    margin: auto;
    padding-top: 24px;
  }
}


// Component Banner Let Discuss in use
.let_discuss {
  .container {
    max-width: 1440px;
    display: flex;
    padding: 64px 24px 24px;
    flex-wrap: wrap;
    @media #{$lg-up} {
      padding: 80px 40px;
    }
    .title {
      font-family: $font-family-title;
      font-size: 32px;
      line-height: 40px;
      font-style: normal;
      font-weight: 400;
      margin: 0;
      text-transform: uppercase;
      padding-bottom:24px;
      @media #{$md-up} {
        font-size: 56px;
        line-height: 64px;
      }
      @media #{$lg-up} {
        font-size: 56px;
        line-height: 64px;
        padding-bottom: 0;
      }
    }
    .tagline {
      padding-bottom: 0;
      @media #{$lg-up} {
        padding-bottom: 40px;
      }
      h2 {
        font-family: $font-family-base;
        margin: 0;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        padding-bottom:24px;
        @media #{$lg-up} {
          padding-bottom: 0;
        }
      }
    }
    .text {
      display: flex;
      flex-wrap: wrap;
      p {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
    }

    .label {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }

    button {
      margin-top: 32px;
      width: 100%;
      @media #{$md-up} {
        width: fit-content;
      }
    }
    //Columns Image fullwidth
    .column_image_fullwidth {
      flex: 0 0 auto;
      width: 100%;
      padding-top: 48px;
      @media #{$md-up} {
        width: fit-content;
        padding-top: 80px;
      }
    }
  }
}

// Section Properties spotlight in use Homepage
.section_properties_spotlight-auto {
  text-align: center;
  .container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 60px 0;
    @media #{$md-up} {
      padding: 80px 0;
    }
  }
  h3 {
    font-family:$font-family-title;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    @media #{$md-up} {
      font-size: 34px;
      line-height: 46px;
    }
    @media #{$lg-up} {
      font-size: 40px;
      line-height: 48px;
    }
  }
  .displayTagTitle{
    p { // SEO option
      font-family:$font-family-title;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      @media #{$md-up} {
        font-size: 34px;
        line-height: 46px;
      }
      @media #{$lg-up} {
        font-size: 40px;
        line-height: 48px;
      }
    }
  }
  &_grid {
    display: flex;
    justify-content: start;
    padding: 48px 30px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 40px;
    @media #{$md-up} {
      padding: 80px 40px;
      margin-bottom: 0;
      justify-content: space-around;
    }
    &-card {
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
      padding: 10px;
      background: none;
      scroll-snap-align: start;
      transition: all 0.2s;
      flex-basis: calc(100% - 10px);
      @media #{$md-up} {flex-basis: calc(50% - 20px);}
      @media #{$lg-up} {flex-basis: calc(40% - 20px);}
    }
    &-cardLarge {
      padding-top:80px;
    }

    &-card:not(:last-child) {
      @media #{$md-up} { }
      @media #{$lg-up} { }
    }
  }
  &_grid::-webkit-scrollbar-thumb,
  &_grid::-webkit-scrollbar-track {
    border-radius: 92px;
  }
  &_grid::-webkit-scrollbar-thumb {
    background: $greydark;
  }
  &_grid::-webkit-scrollbar-track {
    background: #FAFAFA;
  }
  &_grid::-webkit-scrollbar {
    height: 1px;
    @media #{$md-up} {
      height: 0;
    }
  }
  .title {
    padding: 20px 8px 8px;
    font-family:$font-family-title;
    text-transform: uppercase;
    font-size: 22px;
    margin-bottom: 0;
    line-height:28px;
    text-align: center;
    font-weight: 400;
    @media #{$md-up} {
      font-size: 26px;
      line-height:32px;
    }
    @media #{$lg-up} {
      font-size: 32px;
      line-height:40px;
    }
  }
  .description {
    text-align: center;
    margin: 0px;
    @media #{$md-up} {
    }
    @media #{$lg-up} {
    }
  }
}

// Section Press Talk About Us in use  > Homepage + Property Management
.section_press_talk_about_us {
  .container {
    max-width: 1440px;
    padding: 60px 20px;
    @media #{$md-up} {
      padding: 80px 40px;
    }
  }
  .press_logo_zoom {
       @media #{$lg-up} {
         transform: scale(1.8);
       }
     }
}

// Section Why Book With Us in use  > Homepage + Corporate Events
.section_book_with_us {
  .container {
    max-width: 1440px;
    padding: 60px 20px;
    @media #{$md-up} {
      padding: 80px 40px;
    }
  }
}

// Section Block text + image + image fullwidth in use > Destination / Property Management
.destination_block_text {
  .container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 60px 24px 40px;
    @media #{$md-up} {
      padding:80px 40px 40px
    }
  }

  .row_center_title {
    h2 {
      font-family: $font-family-title;
      font-weight: 400;
      text-align: center;
      font-size: 24px;
      line-height: 32px;
      text-transform: uppercase;
      margin: auto;
      padding-bottom: 32px;
      @media #{$md-up} {
        max-width: 670px;
        font-size: 34px;
        line-height: 46px;
      }
      @media #{$lg-up} {
        font-size: 40px;
        line-height: 48px;
      }
    }
  }
  //Columns Text
  .columns_text {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-bottom: 40px;
    padding-top: 40px;

    @media #{$md-up} {
      align-items: center;
      flex-direction: column;
      padding-bottom: 40px;
      padding-top: 40px;

    }
    @media #{$lg-up} {
      flex-direction: row;
      align-items: stretch;
      padding-bottom: 40px;
      padding-top: 40px;
    }
    //Columns Text Left
    &_left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media #{$lg-up} {
        flex: 0 0 auto;
        width: 41.66667%;
        padding-right: 32px;
      }
      h2 {
        text-align: left;
        font-family:$font-family-title;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        text-transform: uppercase;
        margin:0;
        padding-bottom: 40px;
        @media #{$md-up} {
          padding-bottom: 80px;
          font-size: 40px;
          text-align: center;
          line-height: 40px;

        }
        @media #{$lg-up} {
          padding-bottom: 24px;
          font-size: 34px;
          text-align: left;
        }
        @media #{$xl-up} {
          font-size: 40px;
          text-align: left;
          line-height: 48px;
        }
      }

      .section_heading_note {
        color:#828282;
        display: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        max-width: 450px;
        @media #{$lg-up} {
          display: block;
          padding-top: 0;
          margin: 0;
          padding-bottom: 0;
          text-align: left;
        }
      }
    }
    //Columns Text Right
    &_right {
      flex: 0 0 auto;
      width: 100%;
      @media #{$md-up} {
        width: 80%;
      }
      @media #{$lg-up} {
        flex: 0 0 auto;
        max-width: 664px;
        width: 58.33333%;
      }
      .title {
        font-family:$font-family-title;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-transform: uppercase;
        margin:0;
        padding-bottom: 32px;
        @media #{$md-up} {
          font-size: 24px;
          line-height: 32px;
        }
      }
      li {
        p {
          margin-bottom: 0;
        }
      }
      a {
        text-decoration: underline;
        color: black;
      }
      span {
        color: black;
      }
    }
  }

  //Columns Image & Caption
  .columns_image {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    padding-top: 40px;
    @media #{$lg-up} {
      flex-direction: row;
      padding-bottom: 40px;
      padding-top: 40px;
    }

    &_left {
      flex: 0 0 auto;
      width: 80%;
      @media #{$md-up} {
        flex: 0 0 auto;
        width: auto;
      }
      @media #{$lg-up} {
        flex: 0 0 auto;
        width: 58.33333%;
      }
      img {
        width: 100%;
        height: auto;
        @media #{$md-up} {
          width: 530px;
          height: auto;
        }
        @media #{$lg-up} {
          width: 600px;
          height: auto;
        }
        @media #{$xl-up} {
          width: 700px;
          height: auto;
        }
        @media #{$xxl-up} {
          width: 100%;
          height: auto;
        }
      }
    }

    &_right {
      @media #{$lg-up} {
        flex: 0 0 auto;
        width: 41.66667%;
      }
    }
    .image_caption {
      display: none;
      @media #{$lg-up} {
        min-height: 10px;
        display: flex;
        color: #828282;
        flex-direction: column;
        justify-content: flex-end;
      }
      @media #{$lg-up} {
        min-height: 329px;
      }
      @media #{$xl-up} {
        min-height: 385px;
      }
      @media #{$xxl-up} {
        min-height: 455px;
      }
      p {
        height: fit-content;
        margin:0;
      }
      a {
        color: grey;
        text-decoration: none;
      }
    }
    .text-right {
      color: #828282;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      height: fit-content;
      margin: 0;
      text-align: right;
      a {
        color: grey;
      }
    }
  }

  //Columns Image fullwidth & Caption
  .columns_image_fullwidth {
    flex: 0 0 auto;
    width: 100%;
    padding-bottom: 64px;
    @media #{$xl-up} {
      padding-bottom: 80px;
    }
    //caption Fullwidth Image
    p {
      display: none;
      @media #{$lg-up} {
        color: #828282;
        font-size: 16px;
        display: block;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;
        padding-top:16px;
      }
    }
  }

  .displayTagTitle{ // SEO option h2
    h3 {
      text-align: left;
      font-family: $font-family-title;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      text-transform: uppercase;
      margin: 0;
      padding-bottom: 40px;
      @media #{$md-up} {
        padding-bottom: 80px;
        font-size: 40px;
        text-align: center;
        line-height: 40px;

      }
      @media #{$lg-up} {
        padding-bottom: 24px;
        font-size: 34px;
        text-align: left;
      }
      @media #{$xl-up} {
        font-size: 40px;
        text-align: left;
        line-height: 48px;
      }
    }
  }
}

// Section Owner Reviews in use > Destination / Property Management
.section_testimonials_owner {
  padding: 64px 24px 130px 24px;
  @media #{$md-up} {
    padding: 80px 40px 130px 40px;
  }
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 48px;
    @media #{$md-up} {
      padding-bottom: 80px;
    }
    .title {
      font-family:$font-family-title;
      text-transform: uppercase;
      font-weight: 400;
      width: 95%;
      font-size: 24px;
      margin:0;
      padding-bottom:24px;
      line-height: 32px;
      text-align: center;
      @media #{$md-up} {
        width: 80%;
        padding-bottom: 32px;
        font-size: 34px;
        line-height: 46px;
      }
      @media #{$lg-up} {
        width: 75%;
        font-size: 40px;
        line-height: 48px;
      }
      @media #{$xl-up} {
        width: 65%;
        font-size: 40px;
        line-height: 48px;
      }
    }
    .description {
      width: 100%;
      max-width: 680px;
      margin:0;
      padding-bottom: 32px;
      text-align:center;
      @media #{$md-up} {
        padding-bottom: 40px;
        width: 75%;
      }
    }
  }
  .carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 92%;
    border: 1px solid $viridian;
    right: 0;
    opacity: 1;
    border-radius: 4px;
    width: auto;
    height: fit-content;
    padding: 10px;
    @media #{$md-up} {
      position: absolute;
      top: 92%;
      bottom: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      padding: 10px;
      height: fit-content;
      color: $viridian;
      border-radius: 4px;
      text-align: center;
      background: none;
      border: 1px solid $viridian;
      opacity: 1;
      @media #{$lg-up} {
        bottom: 0;
        top: 92%;
      }
    }
  }
  .carousel {
    padding: 0;
    @media #{$lg-up} {
    }
    .carousel-indicators {
      position: absolute;
      right: 0;
      bottom: -5%;
      left: 0;
      margin: 0;
      z-index: 2;
    }
    .carousel-indicators [data-bs-target] {
      background: transparent;
      width: 10px;
      height: 10px;
      border-radius: 1rem;
      margin-right: 6px;
      margin-left: 6px;
      border:1px solid $viridian;
      opacity: 1;
    }
    .carousel-indicators .active {
      background: $viridian;
      opacity: 1;
    }
  }

  &-grid {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    @media #{$lg-up} {
      flex-direction: row;
    }
  }
  &-image {
    img {
      width: 100%;
      height: auto;
      @media #{$sm-up} {
        height: 100%;
        min-height: 500px;
      }
      @media #{$md-up} {
        width: 600px;
        height: 600px;
      }
      @media #{$lg-up} {
        width: 450px;
        height: 450px;
      }
      @media #{$xl-up} {
        width: 560px;
        height:560px;
      }
      @media #{$xxl-up} {
        width: 660px;
        height: 660px;
      }
    }
  }
  &-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    width: 100%;
    min-height: 320px;
    padding-top: 60px;
    padding-bottom: 48px;
    @media #{$sm-up} {
      width: 100%;
      height: 100%;
      min-height: 500px;
    }
    @media #{$md-up} {
      width: 600px;
      height: 600px;
    }
    @media #{$lg-up} {
      width: 450px;
      height: 450px;
    }
    @media #{$xl-up} {
      width: 560px;
      height: 560px;
    }
    @media #{$xxl-up} {
      width: 660px;
      height: 660px;
    }

    .ratings {
      margin-top: -5px;
      width: 85px;
      #rating {
        display: none;
      }
      .stars {
        display: flex;
        margin-top: -16px;
        height: 16px;
      }
      .stars-filled {
        margin-top: -16px;
        display: flex;
        height: 16px;
        overflow: hidden;
      }
    }
    .testimonials-title {
      color: $carbon;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      margin: 1.5rem 0;
      font-family: $font-family-title !important;
      font-weight: 400;
      @media #{$md-up}{
        font-size: 24px;
        line-height: 32px;
      }
      @media #{$lg-up}{
        font-size: 22px;
        line-height: 130%;
        width: 90%;
      }
      @media #{$xl-up}{
        font-size: 30px;
        line-height: 130%;
        width: 70%;
      }
    }

    .testimonials-description {
      font-size: 1rem;
      line-height: 130%;
      margin: 0;
      padding: 0 24px;
      @media #{$md-up}{
        padding: 0 24px;
      }
      @media #{$lg-up}{
        padding: 0 32px;
        font-size: .85rem;
        line-height: 135%;
      }
      @media #{$xl-up}{
        font-size: 1rem;
        padding: 0 80px;
      }
    }

    .testimonials-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;

      .review-avatar {
        width: 40px;
        height: 40px;
        border-radius: 100px;
        margin-bottom: 10px;
        picture {
          width: fit-content;
        }
      }

      //option Avatar with Letter first letter name
      .avatar-circle {
        font-family: $font-family-base;
        font-size: 30px;
        padding-top: 6px;
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        align-items: center;
        font-weight: 400;
        width: 40px;
        height: 40px;
        border-radius: 100px;
      }

      .name {
        font-weight: 400 !important;
        font-size: 1rem;
        text-align: center;
        margin-bottom: 0;
      }

      .city {
        font-family: $font-family-base;
        font-weight: 400;
        font-size: 1rem;
        text-align: center;
        margin-bottom: 0;
      }

      .date {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        text-align: center;
      }
      .testimonials-platform {
        display: flex;
        justify-content: center;
        .posted {
          font-size: .7rem !important;
          margin-bottom: 0 !important;
        }
        .platform-logotype {
          object-fit: initial;
          height: 20px;
        }
      }
    }
  }
}

// Section Our Philosophy
.section_philosophy {
  background-color: $sand-wash;
  height: 100%;
  width: 100%;
  &-background {
    background-image: url("/public/img/mountain_white_02-smallview.png") ;
    background-size: cover;
    margin: auto;
    max-width: 500px;
    position: absolute;
    z-index: 1;
    left: 50%;
    transform:translate(-50%);
    height: 530px;
    background-position: center;
    width: 100%;
    @media #{$md-up} {
      height: 100%;
      max-width: 1440px;
      min-height: auto;
      background-image: url("/public/img/mountain_white_02-HD.png") ;
      background-size: cover;
      background-position: center top;
      transform:translate(-50%, 15%);
      max-height: 580px;
    }
    @media #{$lg-up} {
      height: 100%;
      min-height: 840px;
      max-height: 750px;
      background-size: cover;
      background-position: center top;
      transform:translate(-50%, 0%);

    }
  }
  .container {
    max-width: 1440px;
    padding: 60px 20px;
    z-index: 2;
    position: relative;
    @media #{$md-up} {
      padding: 80px 40px;
    }
  }
  &-titles {
    display: flex;
    color: $viridian;
    flex-wrap: wrap;
    padding: 100px 0;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    align-content: center;
    justify-content: center;
    text-align: center;
    @media #{$md-up} {
      min-height: 415px;
      padding:0;
    }
    @media #{$lg-up} {
      min-height: 450px;
      padding:0;
    }
    .title-1 {
      font-family: $font-family-title;
      font-size: 28px;
      color: $viridian;
      line-height: 32px;
      font-weight: 400;
      text-align: center;
      text-transform: uppercase;
      transition: all 0.5s ease-in-out;
      @media #{$md-up} {
        font-size:56px;
        line-height:64px;
      }
      @media #{$lg-up} {
        font-size:72px;
        line-height:64px;
      }
    }
    .title-2 {
      font-family: $font-family-base;
      font-size:28px;
      color: $viridian;
      line-height:38px;
      font-weight: 400;
      text-align: center;
      transition: all 0.5s ease-in-out;
      text-transform: uppercase;
      @media #{$md-up} {
        font-size:56px;
        line-height:80px;
      }
      @media #{$lg-up} {
        font-size:72px;
        line-height:80px;
      }
    }
  }
  &-description {;
    display: flex;
    flex-wrap: wrap;
    color: $viridian;
    align-content: center;
    justify-content: center;
    text-align: center;
    transition: all 0.5s ease-in-out;
    p {
      font-size: 16px;
      line-height: 24px;
      color: $viridian;
      font-weight: 400;
      @media #{$md-up} {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}

// Section Property Owners
.section_property_owners {
  min-height: 810px;
  height:fit-content;
  transition: height 0.2s linear;
  @media #{$md-up} {
    min-height: 600px;
    height:fit-content;
  }
  @media #{$lg-up} {
    min-height: 600px;
    height:fit-content;
  }
  @media #{$xl-up} {
    min-height:840px;
    height:fit-content;
  }
  .hidden-content {
    height: 0;
    min-height: 0;
    padding: 0;
    margin-top: 0;
    overflow: hidden;
    transition:all 0.5s ease;
  }
  .expanded-content {
    height: fit-content;
    min-height: 50px;
    margin-top: 2rem;
    transition: all 1s ease;
  }

  &-background {
    width: 100%;
    height: 470px;
    background-position-y: 0;
    background-position-x: 45px;
    background-size: 115%;
    background-repeat: no-repeat;
    transition: all 0.2s linear;
    @media #{$sm-up} {
      background-position-y: 0;
      background-position-x: 110px;
      width: 100%;
      height: 470px;
      background-size: 85%;
      background-repeat: no-repeat;
    }
    @media #{$md-up} {
      background-position-y: 0;
      background-position-x: 0;
      width: 50%;
      height: 600px;
      background-size: 770px;
      position: absolute;
      right: 0;
      background-repeat: no-repeat;
    }
    @media #{$lg-up} {
      background-position-y: 0;
      background-position-x: 0;
      width: 50%;
      height: 610px;
      background-size: 770px;
      position: absolute;
      background-repeat: no-repeat;
    }
    @media #{$xl-up} {
      background-position-y: 0;
      background-position-x: 0;
      width: 45%;
      height: 840px;
      background-size: 1000px;
      position: absolute;
      background-repeat: no-repeat;
    }
    @media #{$xxl-up} {
      background-position-y: 0;
      background-position-x: 0;
      width: 41%;
      height: 840px;
      background-size: 1000px;
      position: absolute;
      background-repeat: no-repeat;
    }
  }
  .container {
    max-width: 1440px;
    padding: 60px 20px;
    @media #{$md-up} {
      padding: 80px 40px;
    }

    .section-dropdown {
      position: relative;
      background-color: $sand-wash;
      border-radius: 4px;

      .grid-container {
        display: grid;
        padding: 40px 40px;
        grid-template-columns: repeat(1, 1fr);
        gap: 4px;
        font-size: 14px;
        line-height: 24px;
          @media #{$md-up} {
            grid-template-columns: repeat(2, 1fr);
          }
        @media #{$lg-up} {
          grid-template-columns: repeat(3, 1fr);
          font-size: 16px;
          gap: 8px;
          line-height: 32px;
        }
        @media #{$xl-up} {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
  }
  .heading_section-title {
    text-align: left;
    margin-bottom: 0.5rem;
  }
  h3 {
    font-family:$font-family-title;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    @media #{$md-up} {
      font-size: 26px;
      line-height: 32px;
    }
    @media #{$lg-up} {
      font-size: 34px;
      line-height:46px;
    }
    @media #{$xl-up} {
      font-size: 56px;
      line-height: 64px;
    }
  }
  &-column {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height:0;
    @media #{$md-up}{
      min-height:350px;
    }
    @media #{$lg-up}{
      min-height:450px;

    }
    @media #{$xl-up}{
      min-height:680px;
    }
  }
  &-titles {
    width:100%;
    padding-bottom: 20px;
    @media #{$lg-up}{
      width:95%;
    }
    @media #{$xl-up}{
      width:85%;
    }
    h2 {
      font-family:$font-family-title;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      text-transform: uppercase;
      @media #{$md-up} {
        font-size: 26px;
        line-height: 32px;
      }
      @media #{$lg-up} {
        font-size: 34px;
        line-height:46px;
      }
      @media #{$xl-up} {
        font-size: 56px;
        line-height: 64px;
      }
    }
  }
  &-description {
    width:100%;
    @media #{$lg-up}{
      width:75%;
    }
  }
}

// Component Faqs in use : Booking
.destination_faq {
  .container {
    max-width: 1440px;
    display: flex;
    padding: 64px 24px;
    flex-wrap: wrap;
    @media #{$lg-up} {
      padding: 80px 40px;
    }
  }
  .column-title {
    width: 100%;
    flex: 0 0 auto;
    @media #{$lg-up} {
      width: 25%;
    }
    @media #{$xl-up} {
      width: 41.66667%;
    }
    h2 {
      font-family: $font-family-title;
      text-transform: uppercase;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      padding-bottom: 24px;
      @media #{$md-up} {
        text-align: left;
        font-size: 56px;
        line-height: 64px;
      }
    }
  }

  .column-accordion {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 0 0 auto;
    @media #{$md-up} {
      align-items: start;
    }
    @media #{$lg-up} {
      width: 75%;
    }
    @media #{$xl-up} {
      width: 58.33333%;
    }
    .accordion {
      --bs-accordion-color: black;
      --bs-accordion-bg: transparent;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      padding-bottom: 32px;
      @media #{$md-up} {
        padding-bottom: 48px;
      }
    }
    .accordion-item {
      margin: 0;
      flex: 0 0 auto;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 24px;
      border-bottom: 1px solid $greydark;
      padding-top: 24px;
      width: 100%;
      &:first-child {
        border-top: 1px solid $greydark !important;
      }

      &:last-child {
        border-bottom: 1px solid $greydark !important;
      }
    }
    .accordion-header {
      padding: 0;
      margin: 0;
      @media #{$md-up}{
        border-top: 0;
      }
    }
    .accordion-collapse {
      span {
        color: black!important;
      }
      a {
        text-decoration: underline !important;
      }
      b {
        font-weight: 400;
      }
    }
    .accordion-button {
      color: black;
      border: 0;
      width:95%;
      align-items: center;
      cursor: pointer !important;
      background-color: transparent;
      margin: 0;
      outline: none;
      box-shadow: none;
      text-align: left;
      font-family: $font-family-title !important;
      padding: 0;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-transform: uppercase;

      @media #{$md-up} {
        font-size: 24px;
        line-height: 32px;
        align-items: start;
      }


      //Custom accordion icon bootstrap + show in mobile
      &:after {
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 8H12.6668' stroke='black' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 3.33331V12.6666' stroke='black' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }

      &:not(.collapsed):after {
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 8H12.6668' stroke='black' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        transform: none;
      }

      &:not(.collapsed) {
        color: black;
        background-color: transparent;
        box-shadow: none;
      }

      &:focus {
        border-color: transparent;
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.section_contact_us {
  .container {
    max-width: 1440px;
    padding: 80px 40px;
    margin: auto;

    a {
      text-decoration: none;
    }

    .title {
      font-family: $font-family-title;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      margin: 0;
      line-height: 32px;
      text-transform: uppercase;
      padding-bottom: 32px;
      max-width: 100%;
      @media #{$md-up} {
        font-size: 40px;
        line-height: 48px;
        padding-bottom: 64px;
        max-width:85%;
      }
      @media #{$lg-up} {
        font-size: 40px;
        line-height: 48px;
        max-width: 60%;
      }
      @media #{$xl-up} {
        font-size: 40px;
        line-height: 48px;
        max-width: 50%;
      }
    }
  }
  .label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: $grey;
    line-height: 16px;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    margin: 0;
  }
  p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0.6rem;
  }
  b {
    font-weight: 600;
  }

  .accordion {
    --bs-accordion-color: black;
    --bs-accordion-bg: transparent;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    width: 100%;
    margin: 0;
    padding-bottom: 32px;
    @media #{$md-up} {
      padding-bottom: 48px;
      width: 100%;
    }
  }
  .description_contact_toggle1 {
    width: 95%;
    flex-wrap: wrap;
    display: flex;
    .description_contact_toggle_col {
      flex: 0 0 auto;
      width: 100%;
      padding-right: 24px;
      @media #{$sm-up} {
        width: 50%;
      }
      @media #{$lg-up} {
        width: 25%;
      }
    }
  }

  .description_contact_toggle2 {
    width: 95%;
    flex-wrap: wrap;
    display: flex;
    .description_contact_toggle_col {
      flex: 0 0 auto;
      width: 100%;
      padding-right: 24px;
      @media #{$sm-up} {
        width: 50%;
      }
      @media #{$lg-up} {
        width: 25%;
      }

      &:nth-child(1) {
        padding-right: 60px;
        flex: 0 0 auto;
        width: 100%;
        @media #{$lg-up} {
          width: 50%;
        }
      }
    }
  }

  .accordion-item {
    margin: 0;
    flex: 0 0 auto;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 24px;
    border-bottom: 1px solid $greydark;
    padding-top: 24px;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    width: 100%;
    &:first-child {
      border-top: 1px solid $greydark !important;
    }
    &:last-child {
      border-bottom: 1px solid $greydark !important;
    }
  }
  .accordion-header {
    padding: 0;
    margin: 0;
    @media #{$md-up}{
      border-top: 0;
    }
  }
  .accordion-button {
    color: black;
    border: 0;
    width:95%;
    align-items: center;
    cursor: pointer !important;
    background-color: transparent;
    margin: 0;
    outline: none;
    box-shadow: none;
    text-align: left;
    font-family: $font-family-title !important;
    padding: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    @media #{$md-up} {
      font-size: 24px;
      line-height: 32px;
      align-items: start;
    }



    //Custom accordion icon bootstrap + show in mobile
    &:after{
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 8H12.6668' stroke='black' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 3.33331V12.6666' stroke='black' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }

    &:not(.collapsed):after {
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 8H12.6668' stroke='black' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      transform: none;
    }

    &:not(.collapsed) {
      color: black;
      background-color: transparent;
      box-shadow: none;
    }

    &:focus {
      border-color: transparent;
      outline: none;
      box-shadow: none;
    }
  }
  #successContactUsCall {
    .title {
      font-family: $font-family-title;
      font-size: 24px;
      text-transform: uppercase;
      padding-bottom: 12px;
      max-width: 100%;
    }
  }
}

.cta-AirCall {
  .modal {
    .btn-close {
      background-image: url(/public/img/icons/svg/close-cross.svg);
      width: 16px;
      height: 16px;
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
      background-size: 20px;
      background-repeat: no-repeat;
      outline: none;
      box-shadow: none;
      opacity: 1;
    }
  }
}
/// component Contact Us Call Form. cta-AirCall
.cta-AirCall {
  .modal-xl {
    max-width: 95%;
    margin: 2% auto;
    @media #{$md-up}{
      margin: 24px auto;
      max-width: 750px;
    }
    @media #{$lg-up}{
      margin: 24px auto;
      max-width: 750px;
    }
  }
  .modal-header {
    border: none !important;
    padding: 22px 30px;
  }

  .modal-title {
      font-family: $font-family-title;
      font-weight: 400;
      font-size: 32px;
      font-style: normal;
      line-height: 1.5rem;
      text-transform: uppercase;
  }
  .form-group {
    outline:none;
    box-shadow:none;
    margin-bottom: 1rem;
  }
    label {
      font-family: $font-family-base;
      font-size: 0.875rem;
      margin-bottom: 0.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
    }

  .form-check-newletter-cta {
    .form-check-label {
      font-size: 0.65rem;
      line-height: 29px;
     @media #{$md-up}{
       font-size: 13px;
       padding-top: 0;
       margin-bottom: 0;
     }
    }
  }

  input:focus,
  select:focus,
  textarea:focus,
  {
    outline: none!important;
  ;
  }

  input[type="text"], textarea {
    box-shadow:none !important;
    border:1px solid $sand !important;
    background-color: $sand-wash;
    border-radius: 4px;
    outline: none;
    height: 48px;
  }

  .form-check-input {
    background-color: $sand-wash;
    border-color: $sand;
    border-radius: 2px;

    &:focus {
      border-color: $sand;
      outline: 0;
      box-shadow: none;
    }
  }

  .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0.999512L3.5 6.49951L1 3.99951' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-size: 11px;

  }

  .form-check-input:checked {
    background-color: $viridian;
    border-color: $viridian;
    border-radius: 2px;
    margin-right: 10px !important;

    &:focus {
      border-color: $viridian;
      outline: 0;
      box-shadow: none;
    }
  }

  .form-check-input[type=checkbox] {
    border-radius: 2px;
    margin-right: 10px !important;
  }
  .row {
    margin-right: 0;
    margin-left: 0;
  }
  .custom-checkbox {
    color: $carbon !important;
    accent-color: currentcolor;
  }

  .iti--allow-dropdown {
    width: 100%;
  }

  .box_date_aircall {
    position: absolute;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    max-width: 720px;
    left: -2%;
    top: 25%;
    margin: 2% !important;
    background: $white;
    border-radius: 4px;
    z-index: 1;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, .1);
    @media #{$md-up}{
      display: flex;
      top: 30%;
      flex-direction: row;
      font-size: 1rem;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 1px 5px 1px rgba(0, 0, 0, .1);
      left: -0;
      margin: 2% !important;
      max-width: 720px;
      padding: 2rem 1rem;
      position: absolute;
    }
  }

  .day-label,.day-show {
    font-size: 1rem;
    font-family: $font-family-title;
    text-transform: uppercase;
  }

  .form-check-label {
    font-size: 0.65rem;
    line-height: 28px;
    @media #{$md-up}{
      font-size: 0.85rem;
    }
  }
  .form-control,.form-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none !important;
    box-shadow: none !important;
    background-color: $sand-wash;
    border: 1px solid $sand;
    border-radius: 4px;
    display: block;
    font-size: 16px;
    color: $grey;
    font-weight: 400;
    line-height: 1.5;
    padding: .375rem .75rem;
    width: 100%;
    height: 48px;
  }

  textarea.form-control {
    min-height: calc(4.5em + .75rem + var(--bs-border-width) * 2);
  }
  .day-show {
    padding-left: 0.5rem!important;
    width: 120px;
    display: inline-block;
    font-weight: 400;
    padding-bottom: 0;
    @media #{$md-up}{
      width:50%;
    }
    @media #{$lg-up}{
      width:50%;
    }
  }

  .btn-aircall-toselect {
    min-width: 90px;
    border-radius: 4px !important;
    width: fit-content;
    padding: 0 20px !important;
    background: $sand-wash;
    border: 1px solid $sand;
    color: black;
    font-size: 1rem;
    height: 38px;
    font-family: $font-family-base;
    font-weight: 400;
    @media #{$md-up}{
      width: 43%;
      padding: 0 27px !important;
    }
  }
  .btn-aircall-disabled {
    background: #F2F2F2;
    color: #AAA;
    border: 1px solid $grey;
    font-size: 1rem;  height: 38px;
    font-family: $font-family-base;
    padding: 0 20px !important;
    border-radius: 4px !important;
    width: fit-content;
    min-width: 90px!important;
    @media #{$md-up}{
      width: 43%;
      padding: 0 27px !important;
    }
  }

  .aircall-checkbox-hours {
    padding-left: 2.4rem;
    padding-bottom: 1rem;
  }

  .invalid-feedback{
    color: red;
    margin-top: .25rem;
    width: 100%;
    font-size: 12px !important
  }
  .invalided-dated {color: red;font-size: 12px !important;}

  .btn-selected-date  {
    font-size: 1rem;
    font-family: $font-family-base;
    font-weight: 400;
    margin-top:1rem;
    margin-bottom: 1rem;
    background-color: $sand-wash;
    color: #000;
    width: 100%;
    height: 48px;
    border: 1px solid $sand;
    outline: none!important;
    border-radius: 4px;
    @media #{$md-up}{
      font-size: 1rem;
    }
    &:after {
      content: "";
      background-image: url(/public/img/icons2024/calendar.svg);
      background-repeat: no-repeat;
      background-size: 15px 37px;
      background-position: center;
      display: inline-flex;
      height: 14px;
      opacity: 1;
      width: 38px;
    }
  }
  .iti__country-list {
    border: 1px solid #ccc !important;
    max-width: 275px!important;
    border-radius: 0.25rem!important;
    box-shadow: none!important;
  }
}
.box-alert-success {
  background: #fff;
  border: 0;
  border-radius: 4px;
  left: 50%;
  padding: 40px;
  position: fixed;
  top: 50%;
  display: flex;
  width: 400px;
  height: 300px;
  transform: translate(-50%, -50%);
  z-index: 1060;
  flex-direction: column;
  justify-content: center;
  .title {
    font-family: $font-family-title;
    font-size: 24px;
    text-transform: uppercase;
  }
  .btn-close {
    background-image: url(/public/img/icons/svg/close-cross.svg);
    width: 16px;
    height: 16px;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    background-size: 20px;
    background-repeat: no-repeat;
    outline: none;
    box-shadow: none;
    opacity: 1;
  }
}


.section-embed-code {
  .container {
    max-width: 1440px;
    padding: 48px 24px 64px 24px;
    @media #{$lg-up}{
      padding: 0 40px 80px 40px;
    }
  }
}

// Section Blog Spotlight
.section_blog_spotlight {
  display: flex;
  flex-direction: column;
  align-items: center;
  .container {
    max-width: 1440px;
    padding: 64px 0;
    margin: 0 24px;
    --bs-gutter-x: 0;
    @media #{$lg-up} {
      padding: 80px 0;
      margin: 0 40px;
    }
  }
  h3 {
    font-family:$font-family-title;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    @media #{$md-up} {
      font-size: 34px;
      line-height: 46px;
    }
    @media #{$lg-up} {
      font-size: 56px;
      line-height: 64px;
    }
  }
  .displayTagTitle{ // SEO option
    h2 {
      font-family:$font-family-title;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      text-transform: uppercase;
      @media #{$md-up} {
        font-size: 34px;
        line-height: 46px;
      }
      @media #{$lg-up} {
        font-size: 56px;
        line-height: 64px;
      }
    }
  }
  &_grid {
    display: flex;
    justify-content: start;
    padding: 40px 40px 20px 40px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    &-card {
      h3 {
        text-align: start;
      }
      flex: 0 0 33%;
      padding: 15px;
      background: none;
      scroll-snap-align: start;
      transition: all 0.2s;
      flex-basis: calc(25% - 20px);
      @media #{$md-up} {
        flex-basis: calc(25% - 0px);
      }
      @media #{$lg-up} {
        flex-basis: calc(25% - 0px);
      }
      @media #{$xl-up} {
        flex-basis: calc(22% - 0px);
      }
      .category {
        font-size:12px;
        line-height:16px;
        color: $viridian;
        font-family: $font-family-base;
        font-weight: 400;
        margin: 8px 0;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
      .title {
        font-size:18px;
        line-height:24px;
        font-family: $font-family-title;
        font-weight: 400;
        text-transform: uppercase;
        @media #{$md-up} {
          font-size:18px;
          line-height:24px;
        }
        @media #{$lg-up} {
          font-size:22px;
          line-height:32px;
        }
      }
      .date {
        font-size:16px;
        line-height:24px;
        font-family: $font-family-base;
        font-weight: 400;
        margin: 8px 0;
        .time {
          font-size:16px;
          line-height:24px;
        }
      }
    }
    //setting image
    .even-image {
      max-height: 235px;
      overflow: hidden;
      width: 270px;
      @media #{$sm-up} {
        width: 316px;
        overflow: hidden;
        max-height: 273px;
      }
    }
    .odd-image {
      overflow: hidden;
      width: 270px;
      max-height: 340px;
      @media #{$sm-up} {
        width: 316px;
        overflow: hidden;
        max-height: 353px;
      }
    }

    &-card:first-child  {
      padding-left: 24px;
      @media #{$md-up} {
        padding-left: 40px;
      }
    }
    &-card:last-child  {
      padding-right: 0;
    }

  }
  &_grid::-webkit-scrollbar-thumb,
  &_grid::-webkit-scrollbar-track {
    border-radius: 100px;
  }
  &_grid::-webkit-scrollbar-thumb {
    background: #dad4d1;
  }
  &_grid::-webkit-scrollbar-track {
    background: #FAFAFA;
  }
  &_grid::-webkit-scrollbar {
    height: 4px;
    @media #{$lg-up} {
      height: 4px;
    }
    @media #{$xl-up} {
      height: 0;
    }
  }
}

// Section Bcorp
.section_bcorp {
  background-color: $sand-wash;
  .container {
    max-width: 1440px;
    padding: 60px 20px;
    @media #{$md-up} {
      padding: 80px 40px;
    }
  }
  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    width: 100%;
    flex-direction: column;
    @media #{$md-up} {
      width: 80%;
      flex-direction: row;
    }
    @media #{$lg-up} {
      width: 66.66667%;
      flex-direction: row;
    }
  }
  &-image {
    flex: 0 0 auto;
    width: fit-content;
    @media #{$md-up} {
      flex: 0 0 auto;
      width: 30%;
    }
    @media #{$lg-up} {
      flex: 0 0 auto;
      width: 25%;
    }
  }
  &-description {
    flex: 0 0 auto;
    width: 80%;
    text-align: center;
    padding-top:20px;
    @media #{$md-up} {
      width: 70%;
      text-align: left;
      padding-top:0;
    }
    @media #{$lg-up} {
      width: 70%;
      text-align: left;
      padding-top:0;
    }
    @media #{$xl-up} {
      width: 50%;
      text-align: left;
      padding-top:0;
    }
  }
}

//Use in property List + template-page
.destination_block_text_large {
  .container {
    max-width: 1440px;
    padding: 64px 24px;
    margin: auto;
    @media #{$md-up} {
      padding: 80px 40px;
    }
  }
  h2 {
    text-transform: uppercase !important;
    color: black !important;
  }
  b  {
    font-weight:400!important;
  }
  span {
    color: black !important;
  }
}

.section_team_stats {
  .container {
    max-width: 1440px;
    padding: 60px 20px;
    @media #{$md-up} {
      padding: 80px 40px;
    }
  }
  .number {
    font-family: $font-family-title;
    text-transform: uppercase;
    font-size: 36px;
    line-height: 42px;
    margin: 0;
    font-weight: 400;
  }
  .description {
    max-width: 360px;
    padding-top: 16px;
    margin: auto;
  }
}

// Google Captcha Hide

.grecaptcha-badge {
  display: none !important;
}

.captcha-disclaimer p {
  font-size: 10px !important;
  line-height: 15px !important;
  text-align: center !important;
}

// error message enquire and callback form

.error-message-container, .error-message-container p a {
  color: red !important;
}
