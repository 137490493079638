//Fonts
$font-family-title: 'Louize','Times', serif;
$font-family-base: 'Acumin','Arial', sans-serif;
$font-weight-bold:600;
$font-weight-medium:500;
$font-weight-normal:400;
$font-weight-light:300;
$font-weight-thin:200;

$link-color: #000;

//Colors

$sand-light:#fffbf2 ;
$pine: #093F0C;
$pine-light: #d3dcd5;
$mint: #86ECBD;
$mint-light: #f0fff7;
$rose: #FFE1D8;
$rose-light: #fceeea;
$carbon: #222222;
$smoke: #F6F6F6;
$snow: #FFFFFF;
$blue-light:#f6f7ff;
$white:#FFFFFF;
$black: #000000;
$greydark:#CECECE;

//News colors 2024
$viridian: #00a062;
$sand: #EFE7E3;
$sand-wash: #f5f1ef;
$grey: #828282;
$cloud: #E6E6F2;

//Responsive
$sm-up: "screen and (min-width:576px)";
$md-up: "screen and (min-width:768px)";
$lg-up: "screen and (min-width: 992px)";
$xl-up: "screen and (min-width: 1200px)";
$xxl-up: "screen and (min-width: 1440px)";

