@font-face {
  font-family: 'Acumin';
  src: url('/public/fonts/AcuminRegular.eot');
  src: url('/public/fonts/AcuminRegular.eot?#iefix') format('embedded-opentype'),
  url('/public/fonts/AcuminRegular.woff2') format('woff2'),
  url('/public/fonts/AcuminRegular.woff') format('woff'),
  url('/public/fonts/AcuminRegular.svg#AcuminRegular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Acumin';
  src: url('/public/fonts/AcuminSemibold.eot');
  src: url('/public/fonts/AcuminSemibold.eot?#iefix') format('embedded-opentype'),
  url('/public/fonts/AcuminSemibold.woff2') format('woff2'),
  url('/public/fonts/AcuminSemibold.woff') format('woff'),
  url('/public/fonts/AcuminSemibold.ttf') format('truetype'),
  url('/public/fonts/AcuminSemibold.svg#AcuminPro-Medium') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Louize';
  src: url('/public/fonts/LouizeRegular.eot');
  src: url('/public/fonts/LouizeRegular.eot?#iefix') format('embedded-opentype'),
  url('/public/fonts/LouizeRegular.woff2') format('woff2'),
  url('/public/fonts/LouizeRegular.woff') format('woff'),
  url('/public/fonts/LouizeRegular.ttf') format('truetype'),
  url('/public/fonts/LouizeRegular.svg#Louize') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
